import { defineStore } from 'pinia';
import libraryService from '@/views/library/services/library.service';
import categoryService from '@/views/categories/services/category.service';
import {authStore} from '@/views/auth/stores/auth.store';
import {PDFDocument, rgb} from 'pdf-lib';
import router from "@/router";
import {showSuccessAlert} from "@/helpers/sweetAlert";
import {agendaStore} from "@/views/agenda/stores/agenda.store";
import {userStore} from "@/views/users/stores/user.store";


export const libraryStore = defineStore('libraryStore', {
    state: () => {
        return {
            books: [],
            dashboard_books: [],
            filters: [],
            categories: [],
            book: {
                views: 0,
                downloads: 0,
            },
            queryFilter: '',
            editMode: false,
            file: null,
            image: null,
            form: {
                name: '',
                description: '',
                file_name: '',
                image_name: '',
                is_demo: 0,
                category_ids: [],
                url: ''
            },
            showEditBookModal: false,
            openBookModal: false,
            isLoading: false,
            isLoadingBooks: true,
            isLoadingBook: true,
            isLoadingPDF: true,
            loading: false,
            lastFetched: null,
            pagination: {
                totalPages: 1,
                total: 0,
                currentPage: 1,
                perPage: 12

            }
        }
    },
    actions: {

        resetPagination() {
            this.pagination = {
                totalPages: 1,
                total: 0,
                currentPage: 1,
                perPage: 12
            }
            this.books = [];
        },
        resetForm() {
            this.form = {
                name: '',
                description: '',
                file_name: '',
                is_demo: 0,
                category_ids: []
            };
        },
        resetFilters() {
          this.filters = []
        },

        shouldFetchBooks() {
            const now = new Date();
            if (!this.lastFetched) {
                return true;
            }
            const timeSinceLastFetch = (now - new Date(this.lastFetched)) / 1000;
            return timeSinceLastFetch > 60
        },

        async fetchAllBooks(page = this.pagination.currentPage, mode = null) {
            if (!this.shouldFetchBooks() && !mode) {
                return;
            }

            this.isLoadingBooks = true;

            try {
                if(mode === 'program' || mode === 'userBooks'){
                    this.queryFilter = 'only=name'
                }
                const response = await libraryService.getAllBooks(`${this.queryFilter}&page=${page}&per_page=${this.pagination.perPage}`);
                const newBooks = response.data.data.data;

                if (mode === 'program') {
                    const useAgendaStore = new agendaStore();
                    useAgendaStore.books = response.data.data;
                    this.queryFilter = ''

                } else if (mode === 'userBooks') {
                    const useUserStore = new userStore();
                    useUserStore.books = response.data.data;
                    this.queryFilter = ''

                } else if (mode === 'dashboard') {
                    const useLibraryStore = new libraryStore();
                    useLibraryStore.dashboard_books = newBooks;
                    this.pagination.perPage = 12

                } else {
                    this.pagination.totalPages = response.data.data.last_page;
                    this.pagination.total = response.data.data.total;
                    this.pagination.pagesLoaded++;

                        this.books = newBooks;

                    this.lastFetched = new Date();
                }

            } catch (error) {
                console.error('Error fetching books:', error);
            } finally {
                this.isLoadingBooks = false;
            }
        },

        async fetchAllCategories() {
            try {
                const response = await categoryService.getAllCategories();
                this.categories = response.data.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async fetchBook(uuid, getFiles = true) {
            this.isLoadingPDF = true;
            this.isLoadingBook = true;

            try {
                await libraryService.getBook(uuid).then(async response => {
                    this.book = response.data.data;
                    if (this.book.views === undefined || this.book.views === null) {
                        this.book.views = 0;
                    }
                    if (getFiles) {
                        await this.getBookImage(this.book.image_name);
                        await this.setBookWithWatermark(this.book);
                        this.isLoadingPDF = false;

                    }
                    this.isLoadingBook = false;

                });
            } catch (error) {
                this.isLoadingPDF = false;
                this.isLoadingBook = false;

                if(error.response.status === 404){
                    router.push({path: '/404'})
                }
                console.error(error)
            }
        },
        async deleteBook(uuid) {
            try {
                await libraryService.deleteBook(uuid).then(() => {
                    this.fetchAllBooks();
                });
            } catch (error) {
                console.error('Error deleting book:', error);
            }
        },
        async setBookWithWatermark(book, download = false) {
            try {
                let useAuthStore = authStore();
                console.log('grabbing pdf file')
                const existingPdfBase64 = book.pdf_file;
                console.log('turning into base64')
                const existingPdfBytes = this.base64ToArrayBuffer(existingPdfBase64);
                console.log('load pdf document')

                const pdfDoc = await PDFDocument.load(existingPdfBytes);
                console.log('get the pages')

                const pages = pdfDoc.getPages();
                const watermarkText = `Rechten verleend aan\n\n${useAuthStore.user.first_name} ${useAuthStore.user.last_name}`;
                const fontSize = 30;
                const lineHeight = fontSize + 20;
                const textHeight = lineHeight * 2;

                console.log('start setting up the pages')
                let i = 0;
                pages.forEach(page => {
                    console.log('page: ' + i)
                    const { width, height } = page.getSize();

                    console.log('upper half watermark')
                    // Upper half watermark
                    page.drawText(watermarkText, {
                        x: width / 2 - 150,
                        y: height * 0.75 - textHeight / 2,
                        size: fontSize,
                        opacity: 0.1,
                        color: rgb(0.75, 0.75, 0.75),
                        align: 'center'
                    });

                    console.log('lower half watermark')

                    // Lower half watermark
                    page.drawText(watermarkText, {
                        x: width / 2 - 150,
                        y: height * 0.3 - textHeight / 2,
                        size: fontSize,
                        opacity: 0.1,
                        color: rgb(0.75, 0.75, 0.75),
                        align: 'center'
                    });
                });
                console.log('saving the pdf')

                const pdfBytes = await pdfDoc.save();
                console.log('turning into base64')
                const pdfBase64 = this.arrayBufferToBase64(pdfBytes);

                if (download) {
                    console.log('downloading the pdf...')

                    this.downloadBase64File(this.base64ToBlob(pdfBase64), `${book.file_name.replace('.pdf', '')}-watermarked.pdf`);
                } else {
                    this.file = URL.createObjectURL(this.base64ToBlob(pdfBase64));
                }
            } catch (error) {
                console.error('Error adding watermark:', error);
            }
        },
        arrayBufferToBase64(buffer) {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        },
        base64ToArrayBuffer(base64) {
            const binaryString = window.atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes.buffer;
        },
        downloadBase64File(blob, fileName) {
            let downloadUrl = URL.createObjectURL(blob);

            console.log('set tagname a')
            const link = document.createElement('a');
            console.log('set href and download')
            link.href = downloadUrl;
            console.log('download the file')
            link.download = fileName;

            console.log('append the link to the body')
            document.body.appendChild(link);
            console.log('click on the link')
            link.click();
            console.log('remove link from body')
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);

        },
        async updateBook() {
            this.isLoading = true;
            this.loading = true;
            try {
                this.form.book_id = this.book.uuid;
                await libraryService.updateBook(this.form);
                if (this.form.file) {
                    let formDataPdf = new FormData();
                    formDataPdf.append('pdf', this.form.file);
                    formDataPdf.append('book_uuid', this.form.book_id);
                    await libraryService.uploadBook(formDataPdf);
                }
                if (this.form.image) {
                    let formDataImg = new FormData();
                    formDataImg.append('image', this.form.image);
                    formDataImg.append('book_uuid', this.form.book_id);
                    await libraryService.uploadImage(formDataImg);
                }

                showSuccessAlert('success', 'lesson successfully updated');
                this.isLoading = false;
                await this.closeModal();
                await this.resetPagination();
                await this.fetchAllBooks();
            } catch (error) {
                console.error('Error updating book:', error);
                this.isLoading = false;
            }
        },
        async setQueryFilters() {
            let queryString = '';

            console.log(this.filters);
            this.filters.forEach(filter => {
                if (filter.name !== 'search') {
                    filter.filter.forEach(childFilter => {
                        if (childFilter.checked) {
                            queryString += `categories[]=${childFilter.value}&`;
                        }
                    });
                }
                if (filter.name === 'search') {
                    filter.filter.forEach(searchFilter => {
                        // Only include non-empty search terms
                        if (searchFilter.value && searchFilter.value.trim()) {
                            queryString += `search[]=${searchFilter.value}&`;
                        }
                    });
                }
                if (filter.name === 'only') {
                    filter.filter.forEach(onlyFilter => {
                        if (onlyFilter.checked) {
                            queryString += `only[]=${onlyFilter.value}&`;
                        }
                    });
                }
            });

            queryString = queryString.slice(0, -1);

            this.queryFilter = queryString;
            this.lastFetched = null;
            await this.resetPagination();
            await this.fetchAllBooks();
        },




        async setFormBook() {
            let categoryIds = this.book.categories.map(category => category.id);
            this.form = {
                name: this.book.name,
                description: this.book.description,
                file_name: this.book.file_name,
                image_name: this.book.image_name,
                is_demo: this.book.is_demo,
                categories: this.book.categories,
                category_ids: categoryIds,
                url: this.book.url
            };
        },
        async createBook() {
            this.isLoading = true;
            try {
                await libraryService.postBook(this.form).then(async (response) => {
                    let formDataPdf = new FormData();
                    let formDataImg = new FormData();
                    formDataPdf.append('pdf', this.form.file);
                    formDataPdf.append('book_uuid', response.data.data.uuid);
                    formDataImg.append('book_uuid', response.data.data.uuid);
                    await libraryService.uploadBook(formDataPdf).then(async data => {
                        formDataImg.append('image', this.form.image);
                        await libraryService.uploadImage(formDataImg).then(() => { });

                        this.books = [];
                        await this.resetPagination();
                        await this.closeModal();
                        await this.fetchAllBooks();

                        this.isLoading = false;
                        return data;
                    });
                });

                showSuccessAlert('success', 'lesson successfully added');
            } catch (error) {
                console.error('Error creating book:', error);
                this.isLoading = false;
            }
        },
        async getBookPDF(file_name) {
            try {
                const response = await libraryService.getBookPDF(file_name);
                let PDFBase64 = response.data.data;
                this.file = URL.createObjectURL(this.base64ToBlob(PDFBase64));
                return PDFBase64;
            } catch (e) {
                this.isLoading = false;
            }
        },
        async getBookImage(image_name) {
            try {
                await libraryService.getBookImage(image_name).then(response => {
                    let ImageBase64 = response.data.data;
                    this.image = URL.createObjectURL(this.base64ToBlob(ImageBase64));
                });
            } catch (e) {
                this.isLoading = false;
            }
        },
        async getPDFSource(file_name) {
            await this.getBookPDF(file_name);
            return URL.createObjectURL(this.file);
        },
        closeModal() {
            this.showEditBookModal = false;
            this.form = {};
        },
        base64ToBlob(base64) {
            const binaryString = window.atob(base64);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return new Blob([bytes], { type: 'application/pdf' });
        },
        async incrementViews(uuid) {
            if (uuid) {
                try {
                    const response = await libraryService.incrementBookViews(uuid);
                    if (this.book.uuid === uuid) {
                        this.book.views = response.data.views;
                        this.book = { ...this.book };
                    }
                } catch (error) {
                    console.error('Error incrementing views:', error);
                }
            } else {
                console.error('UUID is not provided');
            }
        },
        async incrementDownloads(uuid) {
            if (uuid) {
                try {
                    console.log('try incrementing download')
                    const response = await libraryService.incrementBookDownloads(uuid);
                    console.log('incremented download')

                    if (this.book.uuid === uuid) {
                        this.book.downloads = response.data.downloads;
                        this.book = { ...this.book };
                    }
                } catch (error) {
                    console.error('Error incrementing downloads:', error);
                }
            } else {
                console.error('UUID is not provided');
            }
        }

    }
});
